<template>
  <div class="wrapper">
    <div class="total-box">
      <div class="item animate__animated animate__backInLeft animate__faster" @click="$router.push({ name: 'CustomerList' })">
        <div class="title">
          <span class="num"> {{ stat.user_today_count || 0 }}</span>
          <span class="label">今日用户</span>
          <div class="sub-title">昨日+{{ stat.user_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInLeft animate__faster" @click="$router.push({ name: 'MerchantList' })">
        <div class="title">
          <span class="num"> {{ stat.store_today_count || 0 }}</span>
          <span class="label">今日商户</span>
          <div class="sub-title">昨日+{{ stat.store_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'OrderList' })">
        <div class="title">
          <span class="num"> {{ stat.order_today_count || 0 }}</span>
          <span class="label">今日完成订单</span>
          <div class="sub-title">昨日+{{ stat.order_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'MerchantWithdrawList' })">
        <div class="title">
          <span class="num"> {{ stat.withdraw_today_count || 0 }}</span>
          <span class="label">今日提现</span>
          <div class="sub-title">昨日+{{ stat.withdraw_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'WithdrawList' })">
        <div class="title">
          <span class="num"> {{ stat.apply_refund_today_count || 0 }}</span>
          <span class="label">今日退款</span>
          <div class="sub-title">昨日+{{ stat.apply_refund_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'OrderList' })">
        <div class="title">
          <span class="num"> {{ stat.wait_pay_today_count || 0 }}</span>
          <span class="label">今日待支付</span>
          <div class="sub-title">昨日+{{ stat.wait_pay_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'OrderList' })">
        <div class="title">
          <span class="num"> {{ stat.wait_jicun_today_count || 0 }}</span>
          <span class="label">今日待寄存</span>
          <div class="sub-title">昨日+{{ stat.wait_jicun_yesterday_count || 0 }}</div>
        </div>
      </div>
      <div class="item animate__animated animate__backInRight animate__faster" @click="$router.push({ name: 'OrderList' })">
        <div class="title">
          <span class="num"> {{ stat.jicun_today_count || 0 }}</span>
          <span class="label">今日寄存中</span>
          <div class="sub-title">昨日+{{ stat.jicun_yesterday_count || 0 }}</div>
        </div>
      </div>
    </div>
    <div class="chart-box">
      <div v-if="show" class="item item animate__animated animate__zoomIn animate__faster">
        <LineChart v-if="show" title="近15天订单新增" :list_x="order_x" :list_y="order_y" />
      </div>
      <div v-if="show" class="item item animate__animated animate__zoomIn animate__faster">
        <LineChart v-if="show" title="近15天用户新增" :list_x="user_x" :list_y="user_y" />
      </div>
    </div>
    <!-- <div style="margin-top: 20px" class="chart-box">
      <LineChart v-if="show" title="近7天订单新增" :list_x="order_x" :list_y="order_y" />
    </div>

    <div style="margin-top: 20px" class="chart-box">
      <LineChart v-if="show" title="近7天用户新增" :list_x="user_x" :list_y="user_y" />
    </div> -->
  </div>
</template>

<script>
import LineChart from './charts/line-chart.vue'
import { getIndexStatAPI } from './api'

export default {
  name: 'Home',
  components: { LineChart },
  data() {
    return {
      show: false,
      stat: {}
    }
  },
  computed: {
    user_x() {
      const { user_days_stat } = this.stat
      if (!user_days_stat) return []
      return Object.keys(user_days_stat)
    },
    user_y() {
      const { user_days_stat } = this.stat
      if (!user_days_stat) return []
      return Object.values(user_days_stat)
    },
    order_x() {
      const { order_days_stat } = this.stat
      if (!order_days_stat) return []
      return Object.keys(order_days_stat)
    },
    order_y() {
      const { order_days_stat } = this.stat
      if (!order_days_stat) return []
      return Object.values(order_days_stat)
    }
  },

  mounted() {
    this.getIndexStat()
  },

  methods: {
    async getIndexStat() {
      this.stat = await getIndexStatAPI()
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  // height: 120vh;
  // background-color: #f0f2f5;
  .total-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    padding: 20px 0;
    padding-top: 0;
    justify-content: center;
    .item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: 50px 0;
      box-shadow: 0 0px 5px 0 rgba(114, 114, 114, 0.1);
      border-radius: 3px;
      transition: all 0.2s linear;
      &:hover {
        // transform: scale(1.01);
        box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1);
        background: #f6f8fc;
      }
      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 14px;
        }
        .num {
          font-weight: 700;
          font-size: 18px;
        }
        .sub-title {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(90%, -80%);
          white-space: nowrap;
          background-color: #0571f2;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 20px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .count {
    height: 207px;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 0;
        &:hover {
          background-color: #f3f8fe;
          border-radius: 10px;
        }
        flex: 1;
        .left {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            &:first-child {
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
        .right {
          background-color: #0571f2;
          line-height: 20px;
          padding: 0 10px;
          border-radius: 20px;
          color: #fff;
        }
      }
    }
  }
  .profit {
    height: 207px;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 28px;
      .left,
      .right {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        &:hover {
          background-color: #f3f8fe;
          border-radius: 10px;
        }
        span {
          &:first-child {
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }
  }
  .activity {
    min-height: 260px;
    margin-bottom: 10px;
  }
  .order {
    height: 260px;
  }
  .chart-box {
    display: flex;
    justify-content: space-between;
    .item {
      flex: 1;
    }
  }
}
</style>
